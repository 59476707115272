import axios from "@axios";
import moment from "moment";

export default {
  fetchWallets({ commit }, params) {
    return new Promise((resolve, reject) => {
      return axios
        .get(`${localStorage.getItem("VUE_APP_API_URL")}/wallets`, {
          params: params,
        })
        .then((response) => {
          if (response.data.wallets) {
            commit("SET_WALLETS", response.data.wallets);
          } else {
            commit("SET_WALLETS", response.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchOneWallet({ commit }, params) {
    return new Promise((resolve, reject) => {
      return axios
        .get(
          `${localStorage.getItem("VUE_APP_API_URL")}/wallets/${params.id}`,
          {
            params: params,
          }
        )
        .then((response) => {
          commit("SET_WALLET", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchWalletFundingHistory({ commit }, wallet_id, dateFrom, dateTo) {
    return new Promise((resolve, reject) => {
      return axios
        .get(`${localStorage.getItem("VUE_APP_API_URL")}/balance_changes`, {
          params: {
            filter: {
              wallet_id: {
                filter: wallet_id,
                type: "equals",
              },
              created_at: {
                type: "inRange",
                filterType: "date",
                dateFrom: dateFrom ?? moment("1/1/2000"),
                dateTo: dateTo ?? moment("1/1/3000"),
              },
            },
          },
        })
        .then((res) => {
          let history = res.data.balance_changes.map((t) => {
            return {
              date: moment(t.created_at).format("Y-MM-DD"),
              amount: parseFloat(t.amount),
            };
          });
          commit("SET_FUNDING_HISTORY", { wallet_id, history });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
