import axios from "@axios";

export default {
  async fetchConversionRate({ commit }, { fromCurrency, toCurrency }) {
    if (fromCurrency === toCurrency) return;
    try {
      let res = await axios(
        `${localStorage.getItem(
          "VUE_APP_API_URL"
        )}//wallets/get_conversion_rate?source=${fromCurrency}&destination=${toCurrency}`
      );
      commit("SET_RATE", {
        fromCurrency,
        toCurrency,
        rate: res.data.conversion_rate,
      });
    } catch (err) {
      console.error(err);
    }
  },
};
