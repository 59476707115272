import Vue from "vue";
import VueRouter from "vue-router";

// Routes
import { canNavigate } from "@/libs/acl/routeProtection";
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from "@/auth/utils";

import routes from "./routes";
import pages from "./pages";

import { changeIdentity } from "@/utils/MultipleIdentities.js";
import jwtDefaultConfig from "@core/auth/jwt/jwtDefaultConfig";
import config from "@/config";


Vue.use(VueRouter);

const appENV = JSON.parse(config.ENV ?? "[]");

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: "/", redirect: { name: "dashboard" } },
    ...routes,
    ...pages,
    ...appENV.map((env) => ({
      path: env?.partner_url?.replace(config.PARTNER_URL, "") + "*",
    })),
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  let fullPath = config.PARTNER_URL + to.fullPath;
  let is_path_include_identity = false 
  let path_include_identity = ""
  appENV.forEach((env) => {
    if (
      env.partner_url != config.PARTNER_URL &&
      fullPath.includes(env.partner_url)
    ) {
      is_path_include_identity = true
      path_include_identity = env.partner_url
      if (env.name != localStorage.getItem("APP_IDENTITY")) {
        changeIdentity(env.name);
      }
    }
  });
  if (is_path_include_identity) return next(fullPath.replace(path_include_identity, ""));

  // if (to.path == '/login' && to.query.token) {
  //   localStorage.setItem(jwtDefaultConfig.storageTokenKeyName, to.query.token);
  //   localStorage.removeItem('userData')
  //   localStorage.removeItem('ib_id')
  // }

  const isLoggedIn = isUserLoggedIn();

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: "auth-login" });

    // If logged in => not authorized
    return next({ name: "misc-not-authorized" });
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    if (to.path == '/login' && to.query.token) {
      localStorage.removeItem('ib_id')
      localStorage.removeItem('userData')
      return next()
    }
    const userData = getUserData();
    next(getHomeRouteForLoggedInUser(userData ? "admin" : null)); //userData.role
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
