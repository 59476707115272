import Vue from "vue"
import {defaultState} from "./modulePartnerState.js"

export default {
  SET_PARTNERS(state, { data }) {
    Vue.set(state, "partners", data)
  },
  SET_IBS(state, { data }) {
    Vue.set(state, "ibs", data)
  },
  SET_IB_ID(state, val) {
    state.current_ib_id = val
    localStorage.setItem("ib_id", val)
  },
  CLEAR_STATE(state) {
    state = Object.assign({}, defaultState())
  },
}


const getPartners = partner => {
  let result = []
  result.push(partner)
  console.log(result)
  partner?.children?.forEach(child => {
    result = [ ...result, ...getPartners(child) ]
  })
  return result
}