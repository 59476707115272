import Vue from "vue"
import {defaultState} from "./moduleWalletState.js"

export default {
  SET_WALLETS(state, wallets) {
    state.wallets = wallets
  },
  SET_WALLET(state, wallet) {
    let index = state.wallets.findIndex(w => w.id == wallet.id)
    if (index == -1) {
      state.wallets.push(wallet)
    }
    else {
      Vue.set(state.wallets, index, wallet)
    }
  },
  SET_FUNDING_HISTORY(state, {wallet_id, history}) {
    Vue.set(state.fundingHistory, wallet_id, history)
  },
  CLEAR_STATE(state) {
    state = Object.assign({}, defaultState())
  }
}