import Vue from "vue"
import {defaultState} from "./moduleClientState.js"

export default {
  SET_CLIENTS(state, {clients}) {
    state.clients = clients
  },
  SET_CLIENT(state, {client}) {
    let index = state.clients.findIndex(x => x.id == client.id)
    if (index == -1) {
      state.clients.push(client)
    } else {
      Vue.set(state.clients, index, client)
    }
  },
  UPDATE_CLIENT(state, {client}) {
    let index = state.clients.findIndex(x => x.id == client.id)
    let current = state.clients[index]
    if (index != -1) {
      Vue.set(state.clients, index, { ...current, ...client })
    }
  },  
  CLEAR_STATE(state) {
    state = Object.assign({}, defaultState())
  },
}