import state from './moduleRateState.js'
import mutations from './moduleRateMutations.js'
import actions from './moduleRateActions.js'
import getters from './moduleRateGetters.js'

export default {
  isRegistered: true,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}