import state from './moduleAccountState.js'
import mutations from './moduleAccountMutations.js'
import actions from './moduleAccountActions.js'
import getters from './moduleAccountGetters.js'

export default {
  isRegistered: true,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}