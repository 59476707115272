export default {
  site_settings: {
    settings: {},
    children: {
      company: {},
      email_settings: {},
      trading_platforms: {},
      account_setup: {},
      transaction_settings: {},
      country_settings: {},
      client_portal_translations: {},
      registration_and_kyc: {}
    }
  },
  companyLogoURL: '',
  companyLogoDarkURL: '',
  companyFaviconURL: ''
}