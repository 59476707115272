<template>
  <ckeditor
    :editor="editor"
    :value="editorData"
    @input="$emit('input', $event)"
    :config="editorConfig"
  ></ckeditor>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import UnderlinePlugin from "@ckeditor/ckeditor5-basic-styles/src/underline";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import ListPlugin from "@ckeditor/ckeditor5-list/src/list";
import HeadingPlugin from "@ckeditor/ckeditor5-heading/src/heading";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload"
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar"
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import FileRepository from "@ckeditor/ckeditor5-upload/src/filerepository";
import CKUploadPlugin from "./uploadPlugin";
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import Font from '@ckeditor/ckeditor5-font/src/font';
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder';

export default {
  components: {
    'ckeditor': CKEditor.component
  },
  props: ["value", "uploadUrl"],
  
  data() {
    return {
      editor: ClassicEditor,
      editorData: this.value,
      editorConfig: {
        plugins: [
          EssentialsPlugin,
          BoldPlugin,
          ItalicPlugin,
          LinkPlugin,
          ParagraphPlugin,
          FileRepository,
          Image,
          ImageResize,
          ImageUpload,
          ImageToolbar,
          HeadingPlugin,
          Table,
          TableToolbar,
          Font,
          FontFamily,
          PasteFromOffice,
          UnderlinePlugin,
          Alignment,
          CKFinder
        ],
        extraPlugins: [CKUploadPlugin],

        toolbar: [ "bold", "italic", "underline", "fontFamily", "fontsize", "fontcolor", "alignment", "link", "imageUpload", 
                  "heading", "undo", "redo", "insertTable",  "fontbackgroundcolor", "ckfinder"],        
        table: {
            contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
        },
        uploadUrl: this.uploadUrl
      },
    };
  },
  watch: {
    value() {
      this.editorData = this.value;
    },
  },
};
</script>
