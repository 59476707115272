import {defaultState} from "./moduleAccountState.js"
import Vue from 'vue'

export default {
  SET_ACCOUNTS(state, accounts) {
    state.accounts = accounts
  },
  SET_ACCOUNT(state, account) {
    let index = state.accounts.findIndex((acc) => acc.id == account.id);
    if (index == -1) {
      state.accounts.push(account)
    }
    else {
      Vue.set(state.accounts, index, account)
    }
  },
  SET_FUNDING_HISTORY(state, {account_id, history}) {
    Vue.set(state.fundingHistory, account_id, history)
    state.fundingHistory[account_id].forEach((item, index) => {
      Vue.set(state.fundingHistory[account_id], index, item)
      Vue.set(state.fundingHistory[account_id][index], "amount", item.amount)
      Vue.set(state.fundingHistory[account_id][index], "date", item.date)
    })
  },
  CLEAR_STATE(state) {
    state = Object.assign({}, defaultState())
  }
}