import Vue from 'vue'
import router from '../router/index'
import axios from 'axios'
import useJwt from '@/auth/jwt/useJwt'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.response.use(
  res => {
      return res
  },
  err => {
      if (err.response.status == 401)  {
          localStorage.removeItem('userData')
          localStorage.removeItem("ib_id")
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
          
          if (router.currentRoute.path !== "/login"){
            router.push({name: 'auth-login', params: {errors : err.response.data, come_from: router.history.current.name}  })
          }          
      }
      else if (err.response.status == 403) {
          router.push({name: 'misc-error', params: {errors : err.response.data, come_from: router.history.current.name}  })
      }
      return Promise.reject(err);
  }

)

Vue.prototype.$http = axiosIns

export default axiosIns
