/*=========================================================================================
  File Name: moduleSettingGetters.js
  Description: Setting Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  partners: (state, getters, rootState, rootGetters) => {
    return state.partners.filter((partner) => partner.user_id != rootGetters['auth/me'].id)
  },
  formattedPartners: (state, getters) => {
    return getters.partners.map((partner) => {
      console.log(partner)
      return {
        ...partner,
        wallet_name: `W${partner?.wallet_id} ${partner?.wallet?.currency}`,
        user_name: partner?.user?.full_name,
        user_email: partner?.user?.email,
        commission_profile_name: partner?.commission_profile?.name,
        master_ib_id: partner?.master_ib?.id
      }
    })
  },

  ibs: (state) => {
    return state.ibs
  },

  currentIb: state => {
    return state.ibs.find(i => i.id == state.current_ib_id) || {}
  },

  currentIbId: (state, getters) => getters.currentIb.id
}


