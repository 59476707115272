// In entrypoint.sh, we replace these __ string with env in runtime
const API_URL =  process.env.VUE_APP_API_URL || '__VUE_APP_API_URL'
const ENV_STRING = process.env.VUE_APP_ENV || '__VUE_APP_ENV'
const PARTNER_URL = process.env.VUE_APP_PARTNER_URL || '__VUE_APP_PARTNER_URL'

let ENV = ENV_STRING
try {
  ENV = atob(ENV, 'base64')
} catch (err) {
  // console.log("Couldn't convert __VUE_APP_ENV. Maybe because it's not in base64. Or: ", err)
}

export default {
  API_URL, ENV, PARTNER_URL
}