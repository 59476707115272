import axios from "@axios";

export default {
  fetchLeads({ commit, rootState }, options) {
    return new Promise((resolve, reject) => {
      return axios
        .get(
          `${localStorage.getItem("VUE_APP_API_URL")}/ibs/${
            rootState.partner.current_ib_id
          }/get_leads`,
          {
            params: {
              ...options,
              filter_by: {
                ...options.filter_by,
              },
            },
          }
        )
        .then((response) => {
          commit("SET_LEADS", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
