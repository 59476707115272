import axios from "@axios";

export default {
  fetchClients({ commit, rootState }, options) {
    return new Promise((resolve, reject) => {
      return axios
        .get(
          `${localStorage.getItem("VUE_APP_API_URL")}/ibs/${
            rootState.partner.current_ib_id
          }/get_clients`,
          {
            params: {
              ...options,
              filter_by: {
                ...options.filter_by,
              },
            },
          }
        )
        .then((response) => {
          commit("SET_CLIENTS", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchSingleClient({ commit }, { client_id, only_update }) {
    return new Promise((resolve, reject) => {
      return axios
        .get(`${localStorage.getItem("VUE_APP_API_URL")}/clients/${client_id}`)
        .then((response) => {
          if (!only_update) {
            commit("SET_CLIENT", { client: response.data });
          } else {
            commit("UPDATE_CLIENT", { client: response.data });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchTradingEquity({ commit, rootState }, clientIds) {
    return axios
      .get(
        `${localStorage.getItem("VUE_APP_API_URL")}/ibs/${
          rootState.partner.current_ib_id
        }/get_client_equity`,
        {
          params: {
            filter_by: {
              id: clientIds,
            },
          },
        }
      )
      .then((res) => {
        res.data.clients.forEach((client) => {
          commit("UPDATE_CLIENT", { client });
        });
      });
  },
};
