import axios from "@axios";

export default {
  fetchIbsOfUser({ commit, getters }) {
    let user = JSON.parse(localStorage.getItem("userData"));
    return new Promise((resolve, reject) => {
      return axios
        .get(
          `${localStorage.getItem("VUE_APP_API_URL")}/partners?user_id=${user.id}`
        )
        .then((res) => {
          commit("SET_IBS", { data: res.data });
          if (!getters.currentIbId || getters.currentIb == {}) {
            const ib = res.data[0]
            commit("SET_IB_ID", ib.id);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchPartners({ commit, getters }) {
    return new Promise((resolve, reject) => {
      return axios
        .get(
          `${localStorage.getItem("VUE_APP_API_URL")}/partners`
        )
        .then((res) => {
          commit("SET_PARTNERS", { data: res.data });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
