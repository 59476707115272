import state from './moduleLeadState.js'
import mutations from './moduleLeadMutations.js'
import actions from './moduleLeadActions.js'
import getters from './moduleLeadGetters.js'

export default {
  isRegistered: true,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}