import Vue from "vue";

export default {
  SET_SETTINGS(state, { path, data, getters }) {
    let pp = [...path];
    if (pp.length > 0) {
      let name = pp.pop()
      
      parent = getters.settingsByPath(pp)
      Vue.set(parent.children, name, data)
    }
    else {
      Vue.set(state, "site_settings", data)
    }
  },

  SET_LOGO(state, { url }) {
    state.companyLogoURL = url
  },

  SET_LOGO_DARK(state, { url }) {
    state.companyLogoDarkURL = url
  },

  SET_FAVICON(state, { url }) {
    state.companyFaviconURL = url
  },

  SET_FIELD(state, { setting_path, field_path, value, getters } ) {
    // data
    field_path = [...field_path]
    var field_name = field_path.pop()
    var parent = getters.fieldFromSetting(setting_path, field_path)

    Vue.set(parent, field_name, value)

    var setting = getters.settingsByPath(setting_path)
    Vue.set(setting, "state", "dirty")
  },

  INSERT_VALUE(state, { setting_path, field_path, value, getters, index = -1 }) {
    var field = getters.fieldFromSetting(setting_path, field_path)
    if (Array.isArray(field)) {
      if (index == -1) {
        field.push(value)
      } else {
        field.splice(index, 0, value)
      }
    }
    var setting = getters.settingsByPath(setting_path)
    Vue.set(setting, "state", "dirty")
  },

  INSERT_SETTING(state, { setting_path, value, getters, index, old_index }){
    let setting = getters.settingsByPath(setting_path)
    let settings = setting.settings
    
    if(index == -1){
      let replace_ojb = settings[settings.length - 1]
      settings[settings.length - 1] = value
      settings.push(replace_ojb)
    }
      
    else {
      
      let replace_ojb = settings[index]
      settings[old_index] = replace_ojb
      settings[index] = value
    }
    Vue.set(setting, "state", "dirty")
  },

  DEL_REGISTRATION_STEP(state, { setting_path, getters, index }){
    let setting = getters.settingsByPath(setting_path)
    Vue.set(setting, "state", "dirty")
    Vue.set(setting, "settings",  setting.settings.filter((it, id) => id != index))
  },

  DELETE_VALUE(state, { setting_path, field_path, index, getters }) {
    var field = getters.fieldFromSetting(setting_path, field_path)
    console.log(field);
    if (Array.isArray(field)) {
      field.splice(index, 1)
    }
    var setting = getters.settingsByPath(setting_path)
    Vue.set(setting, "state", "dirty")
  },

  SET_ERRORS(state, { setting_path, err, getters }) {
    var setting = getters.settingsByPath(setting_path)
    Vue.set(setting, "errors", err)
  },

  SET_LANGUAGE(state, {obj, data}) {
    Vue.set(obj, "data", data)
  },

  ADD_TRADING_SERVER(state, { server, getters }) {
    let setting = getters.settingsByPath(['trading_platforms']).children
    Vue.set(setting, server.short_name, server)
  }
}