import jwtDefaultConfig from "./jwtDefaultConfig";

import MultipleIdentities from "@/utils/MultipleIdentities";
import { changeIdentity } from "@/utils/MultipleIdentities.js";
import axios from "axios";
import Vue from "vue";
import config from "@/config";


export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null;

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // For Refreshing Token
  subscribers = [];

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      (config) => {
        // Get token from localStorage
        const accessToken = this.getToken();

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      (response) => response,
      (error) => {
        const { config, response } = error;
        const originalRequest = config;

        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error);
      }
    );
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    );
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName);
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
  }

  async login(...args) {
    return new Promise(async(resolve, reject) => {
      let appENV = JSON.parse(config.ENV ?? "[]").sort(
        (a, b) => a.order - b.order
      );
      
      var response
      var app_identity = null
      var error
  
      for(let i = 0; i < appENV.length; i++){
        response = await axios.post(`${appENV[i].api_url}/signin`,
          { ...args[0], partner: true }
        ).catch(err => {
          error = err
        })
  
        if (response?.status == 200){
          app_identity = appENV[i]
          break
        }
      }
      if (!app_identity){
        localStorage.removeItem("token");
        reject(error)
        return
      }
  
      changeIdentity(app_identity.name, ()=>{})
  
      let location;
      try {
        location = await axios.get(
          `${config.API_URL}/site_settings/get_location`
        );
      } catch (err) {
        console.error(err);
      }
      const country = location?.data?.[0]?.data?.country;
      console.log(country);
      const { api_url, portal_url } = MultipleIdentities(
        "APP_IDENTITY",
        appENV,
        country
      );
      Object.defineProperty(Vue.prototype, "$api_url", {
        value: api_url,
        writable: true,
      });
      Object.defineProperty(Vue.prototype, "$portal_url", {
        value: portal_url,
        writable: true,
      });
      localStorage.setItem("VUE_APP_API_URL", api_url);
  
      resolve(response)
    })
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args);
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    });
  }
}
