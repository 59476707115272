/*=========================================================================================
  File Name: moduleSettingGetters.js
  Description: Setting Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  leads: (state, getters, rootState, rootGetters) => {
    return state.leads?.map((lead) => {
      return {
        ...lead,
      }
    })
  }  
}


