import { $themeBreakpoints } from '@themeConfig'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from 'vue'
import axios from '@axios'


export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    rightSidebarWidth: 0,
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    SET_SIDEBAR_WIDTH(state, val) {
      state.rightSidebarWidth = val
    },
  },
  actions: {
    notifySusscess({ }, {title, text}){
      Vue.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {title, text, icon: 'CheckIcon', variant: "success"},
      });
    },

    notifyError({ }, {title, text}){
      Vue.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {title, text, icon: 'AlertCircleIcon', variant: "danger"},
      });
    },
  },
}
