import Vue from "vue"

export default {
  SET_RATE(state, {fromCurrency, toCurrency, rate }) {
    if (state.fromCurrency) {
      let obj = { ...state[fromCurrency] }
      obj[toCurrency] = rate
      Vue.set(state, fromCurrency, obj)
    } else {
      let obj = {}
      obj[toCurrency] = rate
      Vue.set(state, fromCurrency, obj)
    }
  }
}