import state from './modulePartnerState.js'
import mutations from './modulePartnerMutations.js'
import actions from './modulePartnerActions.js'
import getters from './modulePartnerGetters.js'

export default {
  isRegistered: true,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}