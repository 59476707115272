import axios from '@axios'
import getBrowserFingerprint from "@/utils/GetBrowserFingerprint.js"

export default {
  namespaced: true,
  state: {
    me: { 
      //...user,
      //employee
      allow_routes: []
    }
  },
  getters: {
    me(state) {
      return state.me
    }
  },
  mutations: {
    SET_ME(state, me) {
      state.me = me
    },
  },
  actions: {
    async fetchMe({ commit, dispatch }) {
      const res = await axios.post(localStorage.getItem("VUE_APP_API_URL")+'/users/me', {
        user: {
          partner: true,
          device_id: getBrowserFingerprint()
        }
      })
      localStorage.setItem('userData', JSON.stringify(res.data))
      commit('SET_ME', res.data)
      await dispatch('partner/fetchIbsOfUser', null, { root: true })
    },

    // async logout({ commit, dispatch }) {
    //   await axios.post(localStorage.getItem("VUE_APP_API_URL")+'/users/logout')
    //   localStorage.removeItem('userData')
    //   localStorage.removeItem('accessToken')
    //   localStorage.removeItem('ib_id')
    //   commit('SET_ME', {})
    //   dispatch('app/setIbId', null, { root: true })
    // }
  }
}
