import state from './moduleClientState.js'
import mutations from './moduleClientMutations.js'
import actions from './moduleClientActions.js'
import getters from './moduleClientGetters.js'

export default {
  isRegistered: true,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}