function checkENV(env, country) {
  if (env.countries == "*") return true;
  let countries = env.countries.split(",").map((code) => code.toUpperCase());
  return countries.includes(country);
}

export function changeIdentity(identity, callback = () => {}) {
  localStorage.setItem("APP_IDENTITY", identity);
  localStorage.removeItem("i18nMessages");
  localStorage.removeItem("cookie_accepted");
  localStorage.removeItem("VUE_APP_API_URL");
  localStorage.removeItem("VUE_APP_PORTAL_URL");
  localStorage.removeItem("userData");
  localStorage.removeItem("accessToken");
  callback();
}

export default function(localStorageKey, appENV, country) {
  let identity_name = localStorage.getItem(localStorageKey);
  let identity = appENV.find((env) => env.name == identity_name);
  if (!identity_name || !identity) {
    appENV.forEach((env) => {
      const valid = checkENV(env, country);

      if (valid && !identity) {
        identity = env;
      }
    });
    localStorage.setItem(localStorageKey, identity.name);
  }
  return identity;
}
