/*=========================================================================================
  File Name: moduleSettingGetters.js
  Description: Setting Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  clients: (state, getters, rootState, rootGetters) => {
    return state.clients.map((client) => {
      return {
        ...client,
        account_equity: client.account_equity != null ? parseFloat(client.account_equity) : null,
        wallet_equity: client.account_equity != null ? parseFloat(client.wallet_equity) : null
      }
    })
  },
}


