/*=========================================================================================
  File Name: moduleEmailActions.js
  Description: Email Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@axios";

export default {
  fetchEmailTemplates({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${localStorage.getItem("VUE_APP_API_URL")}/email_templates`)
        .then((response) => {
          commit(
            "SET_EMAIL_TEMPLATES",
            response.data.sort(function(a, b) {
              if (a.short_name > b.short_name) return 1;
              if (a.short_name < b.short_name) return -1;
              return 0;
            })
          );
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchSingleEmailTemplate({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${localStorage.getItem("VUE_APP_API_URL")}/email_templates/${id}`)
        .then((response) => {
          commit("SET_SINGLE_EMAIL_TEMPLATE", { id, template: response.data });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createEmailTemplate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${localStorage.getItem("VUE_APP_API_URL")}/email_templates`,
          payload.data
        )
        .then((response) => {
          commit("PUSH_EMAIL_TEMPLATE", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateEmailTemplate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${localStorage.getItem("VUE_APP_API_URL")}/email_templates/${
            payload.data.id
          }`,
          payload.data
        )
        .then((response) => {
          commit("UPDATE_EMAIL_TEMPLATE", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteEmailTemplate({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${localStorage.getItem("VUE_APP_API_URL")}/email_templates/${id}`
        )
        .then((response) => {
          commit("DELETE_EMAIL_TEMPLATE", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchTemplateLayouts({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${localStorage.getItem("VUE_APP_API_URL")}/email_layouts`)
        .then((response) => {
          commit("SET_TEMPLATE_LAYOUTS", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createTemplateLayout({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${localStorage.getItem("VUE_APP_API_URL")}/email_layouts`,
          payload.data
        )
        .then((response) => {
          commit("PUSH_TEMPLATE_LAYOUT", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateTemplateLayout({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${localStorage.getItem("VUE_APP_API_URL")}/email_layouts/${
            payload.data.id
          }`,
          payload.data
        )
        .then((response) => {
          commit("UPDATE_TEMPLATE_LAYOUT", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteTemplateLayout({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${localStorage.getItem("VUE_APP_API_URL")}/email_layouts/${id}`
        )
        .then((response) => {
          commit("DELETE_TEMPLATE_LAYOUT", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  renderTemplatePreview({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${localStorage.getItem(
            "VUE_APP_API_URL"
          )}/email_templates/${id}/render_email`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
