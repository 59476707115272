/*=========================================================================================
  File Name: moduleSettingGetters.js
  Description: Setting Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

// import contacts from '@/views/apps/Setting/contacts'

import regions from "@/libs/i18n/regions.js";
import locales from "@/libs/i18n/locales.js";

export default {
  settingsByPath: (state) => (path) => {
    var result = state["site_settings"];
    for (const p of path) {
      if (!result.children) return null;
      result = result.children[p];
      if (result == null) return null;
    }
    return result;
  },

  companyLogoURL: (state) => state.companyLogoURL,
  companyLogoDarkURL: (state) => state.companyLogoDarkURL,
  companyFaviconURL: (state) => state.companyFaviconURL,

  

  errorsByPath: (state, getters) => (setting_path, index_path) => {
    var setting = getters.settingsByPath(setting_path);
    if (!setting || !setting.errors) {
      return "";
    }
    var error = setting.errors.settings[index_path.join("/")];
    return error;
  },
  fieldFromSetting: (state, getters) => (setting_path, index_path) => {
    var setting = getters.settingsByPath(setting_path);
    var field = setting.settings;
    for (const i of index_path) {
      if (field == null) break;
      field = field[i];
    }
    return field;
  },
  companySettings: (state, getters) => {
    return getters.settingsByPath(["company"]);
  },
  getAccountTypes: (state, getters) => {
    let account_types = getters.fieldFromSetting(
      ["account_setup"],
      ["account_types"]
    );
    let result = [];
    if (account_types) {
      for (let account_type of account_types) {
        if (account_type.enabled) result.push(account_type.label);
      }
    }
    return result;
  },
  getTradingPlatforms: (state, getters) => {
    let platforms = getters.settingsByPath(["trading_platforms"]).children;
    let result = [];
    if (platforms) {
      Object.keys(platforms).map((key) => {
        if (key != "default_platform") {
          result.push(key);
        }
      });
    }
    return result;
  },
  getAvailablePlatforms: (state, getters) => {
    let platforms = getters.settingsByPath(["trading_platforms"]).children;
    let result = [];
    if (platforms) {
      for (const [key, value] of Object.entries(platforms)) {
        if (key != "default_platform") {
          result.push(value.settings.platform);
        }
      }
    }
    return [...new Set(result)];
  },
  getCountries: (state, getters) => {
    let countries = getters.settingsByPath(["country_settings"]).settings;
    if (countries) {
      if (countries.white_list && countries.white_list.length)
        return regions.filter((country) =>
          countries.white_list.includes(country.countryShortCode)
        );
      let top_countries = countries.top_countries || [];
      let black_list = countries.black_list || [];
      let results = [];
      results = regions.filter((country) =>
        top_countries.includes(country.countryShortCode)
      );
      results = results.concat(
        regions.filter(
          (country) =>
            !black_list.concat(top_countries).includes(country.countryShortCode)
        )
      );
      return results;
    }
  },
  getLocales: (state, getters) => {
    let company = getters.settingsByPath(["company"]).settings;
    if (company) {
      let langs = company.languages || [];
      return locales.filter((locale) => langs.includes(locale.code));
    }
  },
};
