import axios from "@axios";

export default {
  fetchSettings({ commit, getters }, path = []) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${localStorage.getItem("VUE_APP_API_URL")}/site_settings/${path.join(
            "/"
          )}?recursive`
        )
        .then((res) => {
          // console.log("pathhhh", path, res.data)
          var data = res.data;
          data.state = "clean";
          commit("SET_SETTINGS", { path, data, getters });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchSettingsPublic({ commit, getters }, path = []) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${localStorage.getItem(
            "VUE_APP_API_URL"
          )}/public_site_settings/${path.join("/")}?recursive`
        )
        .then((res) => {
          // console.log("pathhhh", path, res.data);
          var data = res.data;
          data.state = "clean";
          commit("SET_SETTINGS", { path, data, getters });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCompanyLogo({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${localStorage.getItem(
            "VUE_APP_API_URL"
          )}/site_settings/get_company_logo`
        )
        .then((res) => {
          commit("SET_LOGO", { url: res.data });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCompanyLogo({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${localStorage.getItem(
            "VUE_APP_API_URL"
          )}/site_settings/get_company_logo`
        )
        .then((res) => {
          commit("SET_LOGO", { url: res.data });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCompanyLogoDark({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${localStorage.getItem(
            "VUE_APP_API_URL"
          )}/site_settings/get_company_logo_dark`
        )
        .then((res) => {
          commit("SET_LOGO_DARK", { url: res.data });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCompanyFavicon({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${localStorage.getItem(
            "VUE_APP_API_URL"
          )}/site_settings/get_company_favicon`
        )
        .then((res) => {
          commit("SET_FAVICON", { url: res.data });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  commitSettings({ commit, getters }, { path, data }) {
    // Commit setting changes to server

    return new Promise((resolve, reject) => {
      let settings = getters.settingsByPath(path)?.settings;
      if (data) settings = data;

      if (settings.state == "deleted") {
        // POST
        axios
          .post(
            `${localStorage.getItem(
              "VUE_APP_API_URL"
            )}/site_settings/${path.join("/")}`
          )
          .then((res) => {
            commit("DELETE_SETTINGS", { path, getters });
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }
      // PATCH
      console.log("patch patch");
      axios
        .patch(
          `${localStorage.getItem("VUE_APP_API_URL")}/site_settings/${path.join(
            "/"
          )}`,
          { setting: settings }
        )
        .then((res) => {
          let data = res.data;
          data.state = "clean";
          commit("SET_SETTINGS", { path, data, getters });
          resolve(res);
        })
        .catch((err) => {
          commit("SET_ERRORS", {
            setting_path: path,
            err: err.response.data.errors,
            getters,
          });
          reject(err);
        });
    });
  },
  updateLanguage({ commit, getters }, { obj, data }) {
    commit("SET_LANGUAGE", { obj, data, getters });
  },
  updateSettings({ commit, getters }, { path, data }) {
    // Commit setting changes to server
    return new Promise((resolve, reject) => {
      // PATCH
      axios
        .patch(
          `${localStorage.getItem("VUE_APP_API_URL")}/site_settings/${path.join(
            "/"
          )}`,
          { setting: data }
        )
        .then((res) => {
          data.state = "clean";
          commit("SET_SETTINGS", { path, data, getters });
          resolve(res);
        })
        .catch((err) => {
          commit("SET_ERRORS", {
            setting_path: path,
            err: err.response.data.errors,
            getters,
          });
          reject(err);
        });
    });
  },
  deleteSettings({ commit, getters }, path) {
    // Delete setting changes to server
    return new Promise((resolve, reject) => {
      // DELETE
      axios
        .delete(
          `${localStorage.getItem("VUE_APP_API_URL")}/site_settings/${path.join(
            "/"
          )}`
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createSettings({ commit, getters }, { path, data }) {
    // POST
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${localStorage.getItem("VUE_APP_API_URL")}/site_settings/${path.join(
            "/"
          )}`,
          { setting: data }
        )
        .then((res) => {
          if (path[0] === "trading_platforms" && path.length === 1) {
            console.log("res", res);
            commit("ADD_TRADING_SERVER", {
              server: res.data,
              getters,
            });
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  uploadCompanyLogo({ commit, getters }, payload) {
    // POST
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${localStorage.getItem(
            "VUE_APP_API_URL"
          )}/site_settings/upload_company_logo`,
          payload,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          commit("SET_LOGO", { url: res.data });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  uploadCompanyLogoDark({ commit, getters }, payload) {
    // POST
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${localStorage.getItem(
            "VUE_APP_API_URL"
          )}/site_settings/upload_company_logo_dark`,
          payload,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          commit("SET_LOGO_DARK", { url: res.data });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  uploadCompanyFavicon({ commit, getters }, payload) {
    // POST
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${localStorage.getItem(
            "VUE_APP_API_URL"
          )}/site_settings/upload_company_favicon`,
          payload,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          commit("SET_FAVICON", { url: res.data });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  uploadLegalDocument({ commit, getters }, payload) {
    // POST
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${localStorage.getItem(
            "VUE_APP_API_URL"
          )}/site_settings/upload_legal_document`,
          payload,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  setField({ commit, getters }, { setting_path, field_path, value }) {
    commit("SET_FIELD", { setting_path, field_path, value, getters });
  },
  insertValue(
    { commit, getters },
    { setting_path, field_path, value, index = -1 }
  ) {
    commit("INSERT_VALUE", { setting_path, field_path, value, getters, index });
  },
  insertSetting(
    { commit, getters },
    { setting_path, value, index = -1, old_index = -1 }
  ) {
    commit("INSERT_SETTING", {
      setting_path,
      value,
      getters,
      index,
      old_index,
    });
  },
  deleteRegistrationStep({ commit, getters }, { setting_path, index }) {
    commit("DEL_REGISTRATION_STEP", { setting_path, index, getters });
  },
  deleteValue({ commit, getters }, { setting_path, field_path, index }) {
    commit("DELETE_VALUE", { setting_path, field_path, index, getters });
  },
  testEmail({ commit }, testEmail) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${localStorage.getItem("VUE_APP_API_URL")}/site_settings/test_email`,
          { test_email: testEmail }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setError({ commit, getters }, { setting_path, err }) {
    commit("SET_ERRORS", { setting_path: setting_path, err: err, getters });
  },

  enablePromotion({ commit }, promotionId) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${localStorage.getItem(
            "VUE_APP_API_URL"
          )}/promotions/${promotionId}/enable`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  disablePromotion({ commit }, promotionId) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${localStorage.getItem(
            "VUE_APP_API_URL"
          )}/promotions/${promotionId}/disable`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addPromotion({ commit }, promotion) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${localStorage.getItem("VUE_APP_API_URL")}/promotions`,
          promotion
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  editPromotion({ commit }, promotion) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${localStorage.getItem("VUE_APP_API_URL")}/promotions/${
            promotion.id
          }`,
          promotion
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deletePromotion({ commit }, promotionId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${localStorage.getItem("VUE_APP_API_URL")}/promotions/${promotionId}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchPromotion(context, promotionId) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${localStorage.getItem("VUE_APP_API_URL")}/promotions/${promotionId}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
