/*=========================================================================================
  File Name: moduleSettingGetters.js
  Description: Setting Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  accounts: (state) => {
    return state.accounts.map((account) => {
      return {
        ...account,
        name: `${account.account_id} ${account.flatform}-${account.server} ${account.currency}`,
        balance: account.mt_account?.balance ?? 0,
        credit: account.mt_account?.credit ?? 0,
        margin: account.mt_account?.margin ?? 0,
        margin_free: account.mt_account?.margin_free ?? (account.mt_account?.balance ?? 0),
        margin_level: account.mt_account?.margin_level ?? 0,
        profit: ((account.mt_account?.equity ??  parseFloat(account.mt_account?.balance ?? 0) + parseFloat(account.mt_account?.credit ?? 0)) - (account.mt_account?.balance ?? 0)) || 0,
        equity: account.mt_account?.equity ??  parseFloat(account.mt_account?.balance ?? 0) + parseFloat(account.mt_account?.credit ?? 0)
      }
    })
  },

  liveAccounts: (state, getters) => {
    return getters.accounts.filter((a) => a.account_type == 'Live')
  },

  demoAccounts: (state, getters) => {
    return getters.accounts.filter((a) => a.account_type == 'Demo')
  },

  fiatAccounts: (state, getters) => {
    return getters.liveAccounts
  },

  cryptoAccounts: (state, getters) => {
    return []
  },

  sumFiatAccounts: (state, getters) => {
    return sumAccounts(getters.fiatAccounts)
  },

  sumCryptoAccounts: (state, getters) => {
    return sumAccounts(getters.cryptoAccounts)
  },

  accountFundingHistory: (state) => {
    return state.fundingHistory
  }
}

const sumAccounts = (accounts) => {
  return {
    balance: accounts.reduce((sum, a) => sum + a.balance * a.conversion_rate_to_company_currency, 0),
    credit: accounts.reduce((sum, a) => sum + a.credit * a.conversion_rate_to_company_currency, 0),
    equity: accounts.reduce((sum, a) => sum + a.equity * a.conversion_rate_to_company_currency, 0),
  }
}