export default [
  // Dashboard routes
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/dashboard/dashboard/Dashboard.vue"),
  },

  // Clients
  {
    path: "/clients",
    name: "clients",
    component: () => import("@/views/clients/Index.vue"),
    children: [
      {
        path: "accounts",
        name: "clients_accounts",
        component: () => import("@/views/clients/Index.vue"),
      },
    ],
  },

  // Leads
  {
    path: "/leads",
    name: "leads",
    component: () => import("@/views/leads/Index.vue"),
  },

  // Follow-up
  {
    path: "/follow-up",
    name: "follow-up",
    component: () => import("@/views/follow-up/Index.vue"),
  },

  // Partners
  {
    path: "/partners/partner",
    name: "partners",
    component: () => import("@/views/partners/Index.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "email-application",
    },
  },
  {
    path: "/partners/:tab",
    name: "partner-tabs",
    component: () => import("@/views/partners/Index.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "email-application",
      navActiveLink: "partners",
    },
    beforeEnter(to, _, next) {
      if ((to.params.tab = "tree-list")) next();
      else next({ name: "error-404" });
    },
  },

  // Reports
  {
    path: "/reports",
    name: "reports",
    component: () => import("@/views/reports/Index.vue"),
    redirect: "/reports/summary",
    children: [
      {
        path: "summary",
        name: "reports.summary",

        component: () => import("@/views/reports/Report.vue"),
      },
      {
        path: "total-detailed-trading-info",
        name: "reports.total-detailed-trading-info",

        component: () =>
          import("@/views/reports/Report.vue"),
      },
      {
        path: "total-traded-volume",
        name: "reports.total-traded-volume",

        component: () => import("@/views/reports/Report.vue"),
      },
      {
        path: "detailed-trading-per-acc",
        name: "reports.detailed-trading-per-acc",

        component: () => import("@/views/reports/Report.vue"),
      },
    ],
  },

  // Settings
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/views/settings/Index.vue"),
  },

  // *===============================================---*
  // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
  // *===============================================---*
  {
    path: "/apps/email",
    name: "apps-email",
    component: () => import("@/views/apps/email/Email.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "email-application",
    },
  },
  {
    path: "/apps/email/:folder",
    name: "apps-email-folder",
    component: () => import("@/views/apps/email/Email.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "email-application",
      navActiveLink: "apps-email",
    },
    beforeEnter(to, _, next) {
      if (
        ["sent", "draft", "starred", "spam", "trash"].includes(to.params.folder)
      )
        next();
      else next({ name: "error-404" });
    },
  },
  {
    path: "/apps/email/label/:label",
    name: "apps-email-label",
    component: () => import("@/views/apps/email/Email.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "email-application",
      navActiveLink: "apps-email",
    },
    beforeEnter(to, _, next) {
      if (
        ["personal", "company", "important", "private"].includes(
          to.params.label
        )
      )
        next();
      else next({ name: "error-404" });
    },
  },
];
