/*=========================================================================================
  File Name: moduleEmailMutations.js
  Description: Email Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  SET_EMAIL_TEMPLATES(state, emailTemplates) {
    state.emailTemplates = emailTemplates
  },
  SET_SINGLE_EMAIL_TEMPLATE(state, { id, template }) {
    state.emailTemplates = state.emailTemplates.map(emailTemplate => {
      if (emailTemplate.id === id) {
        return template
      } else return emailTemplate
    })
  },
  PUSH_EMAIL_TEMPLATE(state, emailTemplate) {
    state.emailTemplates.push(emailTemplate)
    state.emailTemplates = state.emailTemplates.sort((a, b) => a.short_name > b.short_name ? 1 : -1)
  },
  UPDATE_EMAIL_TEMPLATE(state, emailTemplate) {
    const index = state.emailTemplates.findIndex(el => el.id === emailTemplate.id)
    if (index !== -1) state.emailTemplates.splice(index, 1, emailTemplate);
  },
  DELETE_EMAIL_TEMPLATE(state, emailTemplate) {
    const index = state.emailTemplates.findIndex(el => el.id === emailTemplate.id)
    if (index !== -1) state.emailTemplates.splice(index, 1);
  },
  SET_TEMPLATE_LAYOUTS(state, templateLayouts) {
    state.templateLayouts = templateLayouts
  },
  PUSH_TEMPLATE_LAYOUT(state, templateLayout) {
    state.templateLayouts.push(templateLayout)
    state.templateLayouts = state.templateLayouts.sort((a, b) => a.short_name > b.short_name ? 1 : -1)
  },
  UPDATE_TEMPLATE_LAYOUT(state, templateLayout) {
    const index = state.templateLayouts.findIndex(el => el.id === templateLayout.id)
    if (index !== -1) state.templateLayouts.splice(index, 1, templateLayout);
  },
  DELETE_TEMPLATE_LAYOUT(state, templateLayout) {
    const index = state.templateLayouts.findIndex(el => el.id === templateLayout.id)
    if (index !== -1) state.templateLayouts.splice(index, 1);
  },
}
