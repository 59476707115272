import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import settings from './settings'
import email from './email'
import client from './client'
import account from './account'
import wallet from './wallet'
import rate from './rate'
import lead from './lead'
import auth from './auth'
import partner from './partner'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    settings,
    client,
    account,
    wallet,
    email,
    rate,
    lead,
    auth,
    partner
  },
  strict: process.env.DEV,
})
