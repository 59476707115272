export const defaultState = () => {
  return {
    'USD': {
      'USD': 1
    },
    'JPY': {
      'JPY': 1
    },
    'GBP': {
      'GBP': 1
    },
    'EUR': {
      'EUR': 1
    }
  }
}

export default defaultState();