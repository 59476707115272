import axios from "@axios";
import moment from "moment";

export default {
  fetchAccounts({ commit, rootState }, params) {
    return new Promise((resolve, reject) => {
      return axios
        .get(
          `${localStorage.getItem("VUE_APP_API_URL")}//ibs/${
            rootState.partner.current_ib_id
          }/get_accounts`,
          {
            params: {
              ...params,
              filter_by: {
                ...params.filter_by,
              },
            },
          }
        )
        .then((response) => {
          if (response.data.accounts) {
            commit("SET_ACCOUNTS", response.data.accounts);
          } else {
            commit("SET_ACCOUNTS", response.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchOneAccount({ commit }, params) {
    return new Promise((resolve, reject) => {
      return axios
        .get(
          `${localStorage.getItem("VUE_APP_API_URL")}/accounts/${params.id}`,
          {
            params: params.params,
          }
        )
        .then((response) => {
          console.log(response.data);
          commit("SET_ACCOUNT", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchAccountFundingHistory({ commit }, account_id, dateFrom, dateTo) {
    return new Promise((resolve, reject) => {
      return axios
        .get(`${localStorage.getItem("VUE_APP_API_URL")}/transactions`, {
          params: {
            account_id,
            filter: {
              state: {
                filter: "completed",
                type: "equals",
              },
              action: {
                filter: "transfer",
                type: "equals",
              },
              completed_at: {
                type: "inRange",
                filterType: "date",
                dateFrom: dateFrom ?? moment("1/1/2000"),
                dateTo: dateTo ?? moment("1/1/3000"),
              },
            },
          },
        })
        .then((res) => {
          let history = res.data.transactions.map((t) => {
            return {
              date: moment(t.completed_at).format("Y-MM-DD"),
              amount: parseFloat(
                t.source_type == "Account" ? t.from_amount : t.to_amount
              ),
            };
          });
          commit("SET_FUNDING_HISTORY", { account_id, history });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
