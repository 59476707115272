/*=========================================================================================
  File Name: moduleSettingGetters.js
  Description: Setting Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  wallets: (state) => {
    return state.wallets.map((wallet) => {
      return {
        ...wallet,
        name: wallet.currency,
        equity: parseFloat(wallet.balance) + parseFloat(wallet.amount_locked),
        equity_in_company_currency: (parseFloat(wallet.balance) + parseFloat(wallet.amount_locked)) * wallet.conversion_rate_to_company_currency,
        img: ["USD", "GBP", "EUR", "JPY"].includes(wallet.currency) ? require(`@/assets/images/flags/currency_${wallet.currency}.png`) : "",
      }
    })
  },

  fiatWallets: (state, getters) => {
    return getters.wallets
  },

  cryptoWallets: (state, getters) => {
    return []
  },

  sumFiatWallets: (state, getters) => {
    return sumWallets(getters.fiatWallets)
  },

  sumCryptoWallets: (state, getters) => {
    return sumWallets(getters.cryptoWallets)
  },

  walletFundingHistory: (state) => {
    return state.fundingHistory
  }
}

const sumWallets = (wallets) => {
  return {
    balance: wallets.reduce((sum, w) => sum + w.balance * w.conversion_rate_to_company_currency, 0),
    locked: wallets.reduce((sum, w) => sum + w.amount_locked * w.conversion_rate_to_company_currency, 0),
    equity: wallets.reduce((sum, w) => sum + w.equity * w.conversion_rate_to_company_currency, 0)
  }
}


